import FilmanizeSelectField from "../../../shared/filmanize-select-field";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from "./ducks/reducer";

const CopyCrew = (props) => {
    const { films, copyCrew, selectedFilm, onChangeFilm } = props;

    const filmOptions = films?.map(film => {
        return {
            value: film.id,
            label: film.name
        };
    });

    return (
        <div>
            <FilmanizeSelectField className={OnboardingStep1ClassName} label="Films" options={filmOptions} onChange={onChangeFilm} value={selectedFilm} />
            <button className={`button is-primary ${OnboardingStep2ClassName}`} onClick={copyCrew} disabled={!selectedFilm}>Copy</button>
        </div>
    );
};

export default CopyCrew;
