import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { addEquipmentCategory, clearEquipmentCategory, clearRedirect } from "./ducks/actions";
import Form from "./form";

function AddDepartment(props) {
  const { filmId } = props.match.params;
  const { addEquipmentCategory, clearEquipmentCategory, errors, equipmentCategory } =
    props;

  useEffect(() => {
    clearEquipmentCategory();
  }, [clearEquipmentCategory]);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/equipment-category/${props.redirect.categoryId}/equipment`;
      return <Redirect to={url} />;
    }
  }

  function onAddEquipment(equipmentCategory) {
    sanitiseDataTypes(equipmentCategory);
    addEquipmentCategory(filmId, equipmentCategory);
  }

  function sanitiseDataTypes(equipment) { }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/equipment-category/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        initialValues={equipmentCategory}
        loading={false}
        onSubmit={onAddEquipment}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    errors: state.equipmentCategoryState.errors,
    redirect: state.equipmentCategoryState.redirect,
    equipmentCategory: state.equipmentCategoryState.equipmentCategory
  };
}

export default connect(mapStateToProps, {
  addEquipmentCategory,
  clearEquipmentCategory,
  clearRedirect
})(AddDepartment);
