import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../shared/loader";
import { fetchEquipments } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list-equipments";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListEquipmentsContainer(props) {
  const filmId = props.match.params.filmId;
  const categoryId = props.match.params.categoryId;
  const { fetchEquipments, loading, equipments, errors, readOnly } = props;

  useEffect(() => {
    fetchEquipments(filmId, categoryId);
  }, [filmId, categoryId, fetchEquipments]);

  return (
    <ScrollIntoView
      className="blade-menu"
      path="/films/:filmId/equipment-category/:equipmentCategoryId/equipment"
      loading={loading}>
      {loading && <Loader />}
      <List equipments={equipments} filmId={filmId} categoryId={categoryId} readOnly={readOnly} />
      {!readOnly && !window.location.pathname.endsWith("add") && (
        <NavLink
          to={`/films/${filmId}/equipment-category/${categoryId}/equipment/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Equipment
        </NavLink>
      )}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    readOnly: state.equipmentState.readOnly,
    loading: state.equipmentState.loading,
    errors: state.equipmentState.errors,
    equipments: state.equipmentState.equipments
  };
}

export default connect(mapStateToProps, {
  fetchEquipments
})(ListEquipmentsContainer);
