import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import confirm from "../../../shared/modal/confirm";
import { fetchEquipmentCategory, updateEquipmentCategory, deleteEquipmentCategory, clearRedirect } from "./ducks/actions";
import Form from "./form";

function AddDepartment(props) {
  const { filmId, equipmentCategoryId } = props.match.params;
  const { fetchEquipmentCategory, updateEquipmentCategory, deleteEquipmentCategory, errors, equipmentCategory } =
    props;

  useEffect(() => {
    fetchEquipmentCategory(filmId, equipmentCategoryId);
  }, [equipmentCategoryId]);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = props.redirect.categoryId ? `/films/${filmId}/equipment-category/${props.redirect.categoryId}/equipment` : `/films/${filmId}/equipment-category`;
      return <Redirect to={url} />;
    }
  }

  function onUpdateEquipment(equipmentCategory) {
    sanitiseDataTypes(equipmentCategory);
    updateEquipmentCategory(filmId, equipmentCategory);
  }

  function onDeleteEquipmentCategory(equipmentCategory) {
    confirm(() => deleteEquipmentCategory(filmId, equipmentCategoryId, equipmentCategory), equipmentCategory.name);
  };

  function sanitiseDataTypes(equipment) { }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/equipment-category/:equipmentCategoryId/edit"
      loading={false}>
      <Form
        mode="edit"
        errors={errors}
        initialValues={equipmentCategory}
        loading={false}
        onSubmit={onUpdateEquipment}
        handleDelete={onDeleteEquipmentCategory}

        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    errors: state.equipmentCategoryState.errors,
    redirect: state.equipmentCategoryState.redirect,
    equipmentCategory: state.equipmentCategoryState.equipmentCategory
  };
}

export default connect(mapStateToProps, {
  fetchEquipmentCategory,
  updateEquipmentCategory,
  deleteEquipmentCategory,
  clearRedirect
})(AddDepartment);
