import React from "react";
import { NavLink } from "react-router-dom";

const DepartmentsList = ({ departments, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Departments</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/departments/overview`}
            activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/films/${filmId}/departments/upload`}
            activeClassName="is-active">
            Upload
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/films/${filmId}/departments/copy`}
            activeClassName="is-active">
            Copy
          </NavLink>
        </li>
        <hr />
        {(departments || []).map((department, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/departments/${department.id}/roles`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/departments/${department.id}`
                  );
                }}>
                {department.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default DepartmentsList;
