import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import CopyActors from "./copy";
import { fetchFilms } from "../../film/ducks/actions";
import { copyActors, clearRedirect } from "./ducks/actions";
import { useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import { Redirect } from "react-router-dom";
import Onboarding from "../../../shared/onboarding/onboarding";

const CopyActorsContainer = (props) => {
    const { fetchFilms, copyActors, films, loadingFilms, redirect, clearRedirect, importing, onboardingSteps } = props;
    const { filmId } = props.match.params;

    const [filmsExcludingThisOne, setFilmsExcludingThisOne] = useState<any[]>([]);
    const [selectedFilm, setSelectedFilm] = useState<any>(null);
    const [sameFilm, setSameFilm] = useState<boolean>(true);

    const onChangeFilm = (film) => {
        setSelectedFilm(film);
    };

    useEffect(() => {
        fetchFilms();
    }, []);

    useEffect(() => {
        if (films?.length) {
            setFilmsExcludingThisOne(films.filter(film => film.id != filmId));
        }
    }, [filmId, films?.length]);

    useEffect(() => {
        if (filmsExcludingThisOne?.length) {
            setSelectedFilm(filmsExcludingThisOne[0].id)
        }
    }, [filmId, filmsExcludingThisOne?.length]);

    const onCopyActors = () => {
        debugger;
        copyActors(filmId, selectedFilm, sameFilm);
    };

    if (redirect) {
        if (redirect.to == "list") {
            clearRedirect();
            const url = `/films/${filmId}/actors/overview`;
            return <Redirect to={url} />;
        }
    }

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/actors/copy">
            <h2 className="menu-label">Copy Actors</h2>
            {(loadingFilms || importing) && <Loader />}
            <CopyActors
                films={filmsExcludingThisOne}
                copyActors={onCopyActors}
                onChangeFilm={onChangeFilm}
                selectedFilm={selectedFilm}
                onChangeSameFilm={setSameFilm}
                sameFilm={sameFilm} />
            <Onboarding onboardingSteps={onboardingSteps} section="Copy Actors" />
        </ScrollIntoView>
    );
};

function mapStateToProps(state) {
    const { errors, importing, redirect, onboardingSteps } =
        state.actorsState.copyState;
    return {
        films: state.filmsState.films,
        importing: importing,
        redirect: redirect,
        onboardingSteps: onboardingSteps,
        errors: errors
    };
}

export default connect(mapStateToProps, {
    fetchFilms,
    copyActors,
    clearRedirect
})(CopyActorsContainer);

