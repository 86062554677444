import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import FilmanizeSelectField from "../../../shared/filmanize-select-field";
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName } from "./ducks/reducer";

const CopyActors = (props) => {
    const { films, copyActors, selectedFilm, onChangeFilm, onChangeSameFilm, sameFilm } = props;

    const filmOptions = films?.map(film => {
        return {
            value: film.id,
            label: film.name
        };
    });

    return (
        <div>
            <FilmanizeSelectField className={OnboardingStep1ClassName} label="Films" options={filmOptions} onChange={onChangeFilm} value={selectedFilm} />
            <FilmanizeCheckbox className={OnboardingStep2ClassName} label="Same Film" checked={sameFilm} onChange={onChangeSameFilm} />
            <button className={`button mt-2 is-primary ${OnboardingStep3ClassName}`} onClick={copyActors} disabled={!selectedFilm}>Copy</button>
        </div>
    );
};

export default CopyActors;
