import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const IMPORT_EQUIPMENT = "IMPORT_EQUIPMENT";
export const IMPORT_EQUIPMENT_CLEAR_REDIRECT = "IMPORT_EQUIPMENT_CLEAR_REDIRECT";

export function copyEquipment(filmId, otherFilmId) {

  return (dispatch) => {
    dispatch({
      type: IMPORT_EQUIPMENT,
      payload: getApiClient().post(`/film/${filmId}/Equipment/Copy`, { otherFilmId }),
      meta: {},
    })
      .then((response) => {
        dispatch(showInfo(`Copied equipment`));
      })
      .catch((response) => {
        dispatch(showError(`Error copying equipment`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}


export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_EQUIPMENT_CLEAR_REDIRECT,
    });
  };
}
