import getApiClient from "./../../../../shared/api";

export const FETCH_EQUIPMENT_OVERVIEW = "FETCH_EQUIPMENT_OVERVIEW";
export const DOWNLOAD_EQUIPMENT_OVERVIEW = "DOWNLOAD_EQUIPMENT_OVERVIEW";
export const CLEAR_DOWNLOAD_EQUIPMENT_OVERVIEW = "CLEAR_DOWNLOAD_EQUIPMENT_OVERVIEW";
export const EQUIPMENT_OVERVIEW_DOWNLOADED = "EQUIPMENT_OVERVIEW_DOWNLOADED";

const url = "/film";

export function fetchEquipmentOverview(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_EQUIPMENT_OVERVIEW,
      payload: getApiClient().get(`/film/${filmId}/Equipment/Overview`),
    });
  };
}

export function downloadEquipmentOverview(id, includeDailyRate, categoryIds) {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_EQUIPMENT_OVERVIEW,
      payload: getApiClient().post(`${url}/${id}/equipment/download`, { includeDailyRate, categoryIds }),
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DOWNLOAD_EQUIPMENT_OVERVIEW,
      meta: {},
    });
  };
}

export function pdfDownloaded() {
  return (dispatch) => {
    dispatch({
      type: EQUIPMENT_OVERVIEW_DOWNLOADED,
      meta: {},
    });
  };
}