import React, { useState } from "react";
import { CSVLink } from 'react-csv';
import { Link, NavLink } from "react-router-dom";
import Download from "../../../shared/download";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import FilmanizeSelectField from "../../../shared/filmanize-select-field";
import FilmanizeRadio from "../../../shared/filmanize-radio";

const EquipmentList = ({ equipments,
  filmId,
  currencySymbol,
  onDownloadFile,
  progress,
  download,
  clearPdf,
  includeDailyRate,
  onChangeDailyRate,
  selectedCategories,
  setSelectedCategories,
  categoryOptions,
  csvData,
  filmName }) => {

  const round = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const [DOWNLOAD_PDF, DOWNLOAD_CSV] = [0, 1];

  const [downloadType, setDownloadType] = useState<number>(0);

  const onDownloadTypeChange = (order: number) => {
    setDownloadType(order);
  };

  return (
    <div className="blade-content wide">
      <p className="menu-label">Equipment Overview</p>
      <FilmanizeSelectField label="Categories" value={selectedCategories} onChange={setSelectedCategories} options={categoryOptions} isMulti={true} />
      <div className="mb-1">
        <FilmanizeCheckbox label="Include daily rate" checked={includeDailyRate} onChange={onChangeDailyRate} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Equipment</th>
            {includeDailyRate && <th className="has-text-right">Daily Rate</th>}
          </tr>
        </thead>
        {(equipments || []).map((equipment, idx) => {
          return (
            <>
              <tr key={idx}>
                <td>
                  <Link to={`/films/${filmId}/equipment-category/${equipment.categoryId}/equipment`}>
                    {equipment.category}
                  </Link>
                </td>
                <td>
                  <Link to={`/films/${filmId}/equipment-category/${equipment.categoryId}/equipment/${equipment.id}/edit`}>
                    {equipment.name}
                  </Link>
                </td>
                {includeDailyRate && <td className="has-text-right">
                  <Link to={`/films/${filmId}/equipment-category/${equipment.categoryId}/equipment/${equipment.id}/edit`}>
                    {currencySymbol}{round(equipment.dailyRate)}
                  </Link>
                </td>}
              </tr>
            </>
          );
        })}
        {includeDailyRate &&
          <tfoot>
            <tr>
              <td></td>
              <td className="has-text-right">Total</td>
              <td className="has-text-right">
                {currencySymbol}{round(equipments.reduce((acc, equipment) => acc + equipment.dailyRate, 0))}
              </td>
            </tr>
          </tfoot>
        }
      </table>
      <label className="label">Download</label>
      <div className="buttons">
        <FilmanizeRadio text="PDF" value={DOWNLOAD_PDF} checked={downloadType === DOWNLOAD_PDF} onChange={onDownloadTypeChange} />
        <FilmanizeRadio text="CSV" value={DOWNLOAD_CSV} checked={downloadType === DOWNLOAD_CSV} onChange={onDownloadTypeChange} />
      </div>
      <div className="">
        {downloadType === DOWNLOAD_PDF &&
          <Download
            progress={progress}
            clearPdf={clearPdf}
            download={download}
            downloadFile={onDownloadFile} />}
        {downloadType === DOWNLOAD_CSV &&
          csvData && <CSVLink className="button" data={csvData} filename={`${filmName} Equipment `}>Download</CSVLink>
        }
      </div>
    </div>
  );
};

export default EquipmentList;
