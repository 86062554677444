import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchFilmCrewMembersOverview } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListDepartmentsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchFilmCrewMembersOverview, loading, crewMembers, errors } = props;

  useEffect(() => {
    fetchFilmCrewMembersOverview(filmId);
  }, [filmId, fetchFilmCrewMembersOverview]);

  function renderLoading() {
    return <Loader />;
  }

  function renderDepartments(crewMembers, filmId) {
    return (
      <ScrollIntoView
        path="/films/:filmId/departments/overview"
        loading={loading}>
        <List crewMembers={crewMembers} filmId={filmId} />
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderDepartments(crewMembers, filmId);
}

function mapStateToProps(state) {
  return {
    crewMembers: state.crewMembersOverviewState.crewMembers,
    loading: state.crewMembersOverviewState.loading,
    errors: state.crewMembersOverviewState.errors
  };
}

export default connect(mapStateToProps, {
  fetchFilmCrewMembersOverview
})(ListDepartmentsContainer);
