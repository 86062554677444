import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../shared/loader";
import { uploadActors, clearErrors } from "./ducks/actions";
import Upload from "./upload";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Errors from "../../../shared/errors";
import { OnboardingStep2ClassName } from "./ducks/reducer";
import Onboarding from "../../../shared/onboarding/onboarding";

function UploadActorsContainer(props) {
  const filmId = props.match.params.filmId;
  const { uploadActors, loading, errors, warnings, readOnly, isUploading, clearErrors, onboardingSteps } = props;

  useEffect(() => {
    clearErrors();
  }, []);

  const onUploadActors = (formData) => {
    uploadActors(filmId, formData)
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderActors(filmId) {
    return (
      <ScrollIntoView
        className="blade-content wide"
        path="/films/:filmId/actors"
        loading={loading}>
        <h2 className="menu-label">Upload Actors</h2>
        {errors && <Errors errors={errors} />}
        {warnings && <Errors errors={warnings} isWarning={true} />}
        <Upload filmId={filmId}
          readOnly={readOnly}
          isUploading={isUploading}
          uploadActors={onUploadActors} />
        <a href="/downloads/Actors.csv" className={`${OnboardingStep2ClassName}`}>Example File</a>
        <Onboarding onboardingSteps={onboardingSteps} section="Actor Upload" />
      </ScrollIntoView>
    );
  }

  return renderActors(filmId);
}

function mapStateToProps(state) {
  const uploadState = state.actorsState.uploadState;
  return {
    readOnly: uploadState.readOnly,
    loading: uploadState.loading,
    errors: uploadState.errors,
    warnings: uploadState.warnings,
    isUploading: uploadState.isUploading,
    onboardingSteps: uploadState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  uploadActors,
  clearErrors
})(UploadActorsContainer);
