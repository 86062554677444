import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import CopyEquipment from "./copy";
import { fetchFilms } from "../../film/ducks/actions";
import { copyEquipment, clearRedirect } from "./ducks/actions";
import { useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import { Redirect } from "react-router-dom";
import Onboarding from "../../../shared/onboarding/onboarding";

const CopyEquipmentContainer = (props) => {
    const { fetchFilms, copyEquipment, films, loadingFilms, redirect, clearRedirect, importing, onboardingSteps } = props;
    const { filmId } = props.match.params;

    const [filmsExcludingThisOne, setFilmsExcludingThisOne] = useState<any[]>([]);
    const [selectedFilm, setSelectedFilm] = useState<any>(null);

    const onChangeFilm = (film) => {
        setSelectedFilm(film);
    };

    useEffect(() => {
        fetchFilms();
    }, []);

    useEffect(() => {
        if (films?.length) {
            setFilmsExcludingThisOne(films.filter(film => film.id != filmId));
        }
    }, [filmId, films?.length]);

    useEffect(() => {
        if (filmsExcludingThisOne?.length) {
            setSelectedFilm(filmsExcludingThisOne[0].id)
        }
    }, [filmId, filmsExcludingThisOne?.length]);

    const onCopyEquipment = () => {
        copyEquipment(filmId, selectedFilm);
    };

    if (redirect) {
        if (redirect.to == "list") {
            clearRedirect();
            const url = `/films/${filmId}/equipment-category/overview`;
            return <Redirect to={url} />;
        }
    }

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/equipment-category/copy">
            <h2 className="menu-label">Copy Equipment</h2>
            {(loadingFilms || importing) && <Loader />}
            <CopyEquipment films={filmsExcludingThisOne} copyEquipment={onCopyEquipment} onChangeFilm={onChangeFilm} selectedFilm={selectedFilm} />
            <Onboarding onboardingSteps={onboardingSteps} section="Copy Equipment" />
        </ScrollIntoView>
    );
};

function mapStateToProps(state) {
    const { errors, importing, redirect, onboardingSteps } = state.equipmentCopyState

    return {
        films: state.filmsState.films,
        importing: importing,
        redirect: redirect,
        onboardingSteps: onboardingSteps,
        errors: errors
    };
}

export default connect(mapStateToProps, {
    fetchFilms,
    copyEquipment,
    clearRedirect
})(CopyEquipmentContainer);

