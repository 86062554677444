import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const IMPORT_CREW = "IMPORT_CREW";
export const IMPORT_CREW_CLEAR_REDIRECT = "IMPORT_CREW_CLEAR_REDIRECT";

export function copyCrew(filmId, otherFilmId) {

  return (dispatch) => {
    dispatch({
      type: IMPORT_CREW,
      payload: getApiClient().post(`/film/${filmId}/CrewMember/Copy`, { otherFilmId }),
      meta: {},
    })
      .then((response) => {
        dispatch(showInfo(`Copied crew`));
      })
      .catch((response) => {
        dispatch(showError(`Error copying crew`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}


export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_CREW_CLEAR_REDIRECT,
    });
  };
}
