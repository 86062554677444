import Dropzone from "react-dropzone";
import { OnboardingStep1ClassName } from "./ducks/reducer";

const Upload = (props) => {
  const {
    readOnly,
    isUploading,
    uploadActors
  } = props;

  const acceptedFileTypes = [".csv"];

  const onUploadActors = (formData) => {
    uploadActors(formData);
  };

  return (
    <>
      <Dropzone
        accept={acceptedFileTypes.join()}
        multiple={false}
        disabled={isUploading || readOnly}
        onDrop={(acceptedFiles, rejectedFiles) => {
          if (acceptedFiles && acceptedFiles.length > 0) {
            // setSaving(true);
            const file = acceptedFiles[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("filename", file.name);
            onUploadActors(formData);
          }

          if (rejectedFiles && rejectedFiles.length > 0) {
            const fileName = rejectedFiles[0].file.name;
            const lastDot = fileName.lastIndexOf(".");
            const extention = fileName.substring(lastDot);
            // setUploadError(
            //   "File type " + extention + " is not supported"
            // );
          }
        }}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section
            className={`image-drop-area ${OnboardingStep1ClassName} ${isDragActive ? "active" : ""}`}>
            <div
              {...getRootProps({ className: `image-drop-area-inner ${isDragActive ? "active" : ""}` })}>
              <input {...getInputProps()} />
              <button className={`button ${isUploading ? "is-loading" : ""}`} disabled={isUploading || readOnly}>Choose a file…</button>
            </div>
          </section>
        )}
      </Dropzone>
    </>
  );
}

export default Upload;