import { IMPORT_BACKGROUND_ACTORS, IMPORT_BACKGROUND_ACTORS_CLEAR_REDIRECT } from "./actions";

export const OnboardingStep1ClassName = 'background-actors-import-onboarding-step-1';
export const OnboardingStep2ClassName = 'background-actors-import-onboarding-step-2';
export const OnboardingStep3ClassName = 'background-actors-import-onboarding-step-3';

const defaultState = {
  importing: false,
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Films',
      content: `        
        Select which film you want to copy background actors from.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Same Film',
      content: `<p>If this film has the same script as the one you are copying from select this. It will then keep the signed release setting.</p>
                <p>If this is a brand new film, then uncheck this to reset the signed release to not signed.</p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Copy',
      content: `Click the copy button to copy the background actors.`
    }
  ]
};

// and attempt to assign the actors to the same characters


const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case IMPORT_BACKGROUND_ACTORS + "_PENDING": {
      return {
        ...state,
        errors: null,
        importing: true
      };
    }

    case IMPORT_BACKGROUND_ACTORS + "_FULFILLED": {
      return {
        ...state,
        importing: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case IMPORT_BACKGROUND_ACTORS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        importing: false
      };
    }

    case IMPORT_BACKGROUND_ACTORS_CLEAR_REDIRECT: {
      return {
        ...state,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
