import { sortByName } from "./../../../../shared/utility";
import { CLEAR_DOWNLOAD_EQUIPMENT_OVERVIEW, DOWNLOAD_EQUIPMENT_OVERVIEW, EQUIPMENT_OVERVIEW_DOWNLOADED, FETCH_EQUIPMENT_OVERVIEW } from "./actions";

interface EquipmentOverviewState {
  equipment: any;
  loading: boolean;
  readOnly: boolean;
  download: any;
  progress: any;
}

const defaultState: EquipmentOverviewState = {
  equipment: [],
  loading: true,
  readOnly: false,
  download: null,
  progress: null,
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_EQUIPMENT_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: true,
        download: null,
        progress: null,
        errors: null
      };
    }

    case FETCH_EQUIPMENT_OVERVIEW + "_FULFILLED": {
      return {
        ...state,
        equipment: action.payload.data.equipment,
        loading: false,
        errors: null
      };
    }


    case FETCH_EQUIPMENT_OVERVIEW + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }


    case DOWNLOAD_EQUIPMENT_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case DOWNLOAD_EQUIPMENT_OVERVIEW + "_FULFILLED": {
      return state;
    }

    case DOWNLOAD_EQUIPMENT_OVERVIEW + "_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case EQUIPMENT_OVERVIEW_DOWNLOADED: {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case CLEAR_DOWNLOAD_EQUIPMENT_OVERVIEW: {
      return {
        ...state,
        download: null,
        process: null
      };
    }

    case DOWNLOAD_EQUIPMENT_OVERVIEW + "_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case DOWNLOAD_EQUIPMENT_OVERVIEW + "_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    default:
      return state;
  }
};

export default reducer;
