import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchSetDressings } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListSetDressingsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchSetDressings, loading, setDressings, errors, readOnly } = props;

  useEffect(() => {
    fetchSetDressings(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderSetDressings(setDressings, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/set-dressings/overview"
        loading={loading}>
        <List setDressingItems={setDressings} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/set-dressings/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Set Dressing
        </NavLink>}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderSetDressings(setDressings, filmId);
}

function mapStateToSetDressings(state) {
  const { setDressings, loading, errors, readOnly } = state.setDressingsState;
  return { setDressings, loading, errors, readOnly };
}

export default connect(mapStateToSetDressings, {
  fetchSetDressings
})(ListSetDressingsContainer);
