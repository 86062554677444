import React from "react";
import { Link, NavLink } from "react-router-dom";

const EquipmentCategoriesList = ({ categories, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Equipment Categories</p>

      <ul className="menu-list">
        <li>
          <NavLink to={`/films/${filmId}/equipment-category/overview`} activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink to={`/films/${filmId}/equipment-category/copy`} activeClassName="is-active">
            Copy
          </NavLink>
        </li>
        <hr />
        {(categories || []).map((category, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/equipment-category/${category.id}/equipment`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/equipment-category/${category.id}/equipment`
                  );
                }}>
                {category.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default EquipmentCategoriesList;
