import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const IMPORT_ACTORS = "IMPORT_ACTORS";
export const IMPORT_ACTORS_CLEAR_REDIRECT = "IMPORT_ACTORS_CLEAR_REDIRECT";

export function copyActors(filmId, otherFilmId, sameFilm) {
  debugger;
  return (dispatch) => {

    dispatch({
      type: IMPORT_ACTORS,
      payload: getApiClient().post(`/film/${filmId}/Actor/Copy`, { otherFilmId, sameFilm }),
      meta: {},
    })
      .then((response) => {
        dispatch(showInfo(`Copied actors`));
      })
      .catch((response) => {
        dispatch(showError(`Error copying actors`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}


export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_ACTORS_CLEAR_REDIRECT,
    });
  };
}
