import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchFilms, addExampleFilm, deleteFilm, clearUserFilm } from "./film/ducks/actions";
import 'react-loading-skeleton/dist/skeleton.css';
import UploadIcon from "./../films/icons/upload";
import AddIcon from "./../films/icons/add";
import "./index.scss";
import Onboarding from '../shared/onboarding/onboarding';
import FilmCard from './film-card';
import SkeletonCard from "./skeleton-card";

const FilmList = (props) => {
  const {
    films,
    canAddFilm,
    loading,
    fetchFilms,
    addExampleFilm,
    userName,
    filmOnboardingSteps,
    deleteFilm,
    clearUserFilm
  } = props;

  useEffect(() => {
    fetchFilms();
    clearUserFilm();
  }, []);

  const setFilmDetails = (filmId: any) => {

  };

  return (
    <section className="section main-content background-f">
      <div className="blade-content widest film-list">
        <div className="container film-list">
          <h1>Welcome back {userName}</h1>
          <div className="box get-started">
            <div className="column">
              <div className="get-started-text">Get Started!</div>
            </div>
            {canAddFilm && <>
              <div className="column has-text-centered">
                <p className="get-started-heading">Already have a script?</p>
                <p className="get-started-sub-heading">
                  Import a new film by uploading script.
                </p>
                <NavLink
                  to={`/script/upload`}
                  className="button mt-1 is-transparent get-started onboarding-step-1">
                  <UploadIcon className="upload-icon" />
                  Upload Script
                </NavLink>

              </div>
              <div className="vl"></div>
              <div className="column has-text-centered">
                <p className="get-started-heading">Don't have a script?</p>
                <p className="get-started-sub-heading">
                  Create a new film manually.
                </p>
                <NavLink
                  to={`/films/add`}
                  className="button mt-1 is-transparent get-started onboarding-step-2">
                  <AddIcon className="upload-icon" />
                  Add Film
                </NavLink>
              </div>
            </>}
            {!canAddFilm && <>
              <div className="column has-text-centered">You cannot add any more films. Please delete or archive some, or change package in the <Link to={'/account'} className="link">account</Link> section to allow adding more.</div>
              <div className="column has-text-centered"></div>
            </>}
          </div>

          <h1 className="mt-2 mb-1">My Films</h1>
          {loading && <SkeletonCard />}
          {!loading && <div>
            <div className="columns is-multiline">
              {(films || []).map((film) => {
                let filmName = film.name;
                let tooLong = false;
                const maxLength = 50;
                if (filmName.length > maxLength) {
                  filmName = filmName.substring(0, maxLength) + " ...";
                  tooLong = true;
                }

                return (
                  <FilmCard film={film} deleteFilm={deleteFilm} />
                );
              })}
            </div>
            <button className="button" onClick={addExampleFilm}>
              Add Example Film
            </button>
          </div>}
        </div>

        {/*
      <button className="button is-text is-small" onClick={addExampleFilm}>
        Add Example Film
      </button>
      */}
      </div>

      <Onboarding onboardingSteps={filmOnboardingSteps} section="Films" />

    </section>
  );
};

function mapStateToProps(state) {
  return {
    films: state.filmsState.films,
    canAddFilm: state.filmsState.canAddFilm,
    loading: state.filmsState.loading,
    userName: state.commonState.user?.firstName,
    filmOnboardingSteps: state.filmsState.filmOnboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchFilms,
  deleteFilm,
  addExampleFilm,
  clearUserFilm
})(FilmList);
