import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../notifications/ducks/actions";

import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel
} from "@microsoft/signalr"; // version 1.0.4

import { startSignalRConnection } from './startSignalRConnection';


import auth from "../shared/auth";
import { DOWNLOAD_PROPS_OVERVIEW } from "../films/accessories/props/overview/ducks/actions";
import { DOWNLOAD_SHOOTING_DAY_CATERING } from "../films/shooting-days/catering/ducks/actions";
import { UPDATE_PERSON_DETAILS_EMAIL_STATUS, UPDATE_SEND_PERSON_DETAILS_PROGRESS } from "../films/shooting-days/messages/ducks/actions";
import { UPLOAD_SCRIPT_ERROR } from "../film-scripts/ducks/actions";
import { DOWNLOAD_EQUIPMENT_OVERVIEW } from "../films/equipment/overview/ducks/actions";

// const startSignalRConnection = (connection) =>
//   connection
//     .start()
//     .then(() => console.info("SignalR Connected"))
//     .catch((err) => console.error("SignalR Connection Error: ", err));

export const DOWNLOAD_SCRIPT = "DOWNLOAD_SCRIPT";
export const UPDATE_CALL_SHEET_PERSON_EMAIL_STATUS = "UPDATE_CALL_SHEET_PERSON_EMAIL_STATUS";
export const UPDATE_SMART_BREAKDOWN_PROGRESS = "UPDATE_SMART_BREAKDOWN_PROGRESS";
export const UPDATE_SCRIPT_UPLOAD_ERROR = "UPDATE_SCRIPT_UPLOAD_ERROR";

export function signalRMiddleware(store: any) {
  return (next: any) => (action: any) => {
    // register signalR after the user logged in
    if (action.type === "LOGIN_FULFILLED" || action.type === "USER_LOADED") {
      const urlRoot = process.env.REACT_APP_API_URL || (window as any).apiUrl;
      let url = urlRoot.substring(0, urlRoot.length - 1);
      var to = url.lastIndexOf("/");
      to = to == -1 ? url.length : to + 1;
      url = url.substring(0, to);
      const connectionHub = url + `sendhub`;

      const protocol = new JsonHubProtocol();

      // let transport to fall back to to LongPolling if it needs to
      const transport =
        HttpTransportType.WebSockets | HttpTransportType.LongPolling;

      const options = {
        transport,
        logMessageContent: true,
        logger: LogLevel.Error,
        accessTokenFactory: () => auth.getSignalRToken()
      };

      // create the connection instance
      const connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();

      connection.off("SendCallSheetProgress");
      connection.off("SendCallSheetErrored");
      connection.off("CallSheetPersonEmailStatus");
      connection.off("SendPersonDetailsProgress");
      connection.off("PersonDetailsEmailStatus");
      connection.off("SendScriptUploadProgress");
      connection.off("SendScriptUploadCompleted");
      connection.off("SendScriptUploadErrors");
      connection.off("SmartBreakdownProgress");
      connection.off("DownloadSceneBreakDownComplete");
      connection.off("DownloadProgress");
      connection.off("DownloadShootingDaysProgress");
      connection.off("DownloadShootingDaysComplete");
      connection.off("DownloadShootingDaysErrored");
      connection.off("DownloadShotListProgress");
      connection.off("DownloadShotListComplete");
      connection.off("DownloadShotListErrored");
      connection.off("DownloadCallSheetProgress");
      connection.off("DownloadCallSheetComplete");
      connection.off("DownloadCallSheetErrored");
      connection.off("DownloadBudgetComplete");
      connection.off("DownloadBudgetProgress");
      connection.off("DownloadBudgetErrored");
      connection.off("AddUserMessage");
      connection.off("RemoveUserMessage");
      connection.off("DismissUserMessage");
      connection.off("DownloadDayOutOfDaysComplete");
      connection.off("DownloadDayOutOfDaysProgress");
      connection.off("DownloadDayOutOfDaysErrored");
      connection.off("DownloadScriptComplete");
      connection.off("DownloadScriptProgress");
      connection.off("DownloadScriptErrored");

      // event handlers, you can use these to dispatch actions to update your Redux store
      connection.on("SendCallSheetProgress", (progress, message) => {
        store.dispatch({
          type: "UPDATE_SEND_CALL_SHEET_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("SendPersonDetailsProgress", (progress, message) => {
        store.dispatch({
          type: UPDATE_SEND_PERSON_DETAILS_PROGRESS,
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("PersonDetailsEmailStatus", (personId, personType, status, statusDescription, statusChangedDate) => {
        store.dispatch({
          type: UPDATE_PERSON_DETAILS_EMAIL_STATUS,
          meta: { personId, personType, status, statusDescription, statusChangedDate }
        });
        if (status === 5) {
          store.dispatch(showError("Email bounced. See notifications for details."));
          store.dispatch(clearNotifications());
        }
      });

      connection.on("SendCallSheetErrored", (message) => {
        store.dispatch({
          type: "UPDATE_SEND_CALL_SHEET_ERRORED",
          meta: { message }
        });
      });

      connection.on("CallSheetPersonEmailStatus", (personId, personType, status, statusDescription, statusChangedDate) => {
        store.dispatch({
          type: UPDATE_CALL_SHEET_PERSON_EMAIL_STATUS,
          meta: { personId, personType, status, statusDescription, statusChangedDate }
        });
        if (status === 5) {
          store.dispatch(showError("Email bounced. See notifications for details."));
          store.dispatch(clearNotifications());
        }
      });

      connection.on("SendScriptUploadProgress", (progress, message, error) => {
        if (error) {
          store.dispatch({
            type: UPLOAD_SCRIPT_ERROR,
            meta: { error: message }
          });
        }

        store.dispatch({
          type: "UPDATE_SCRIPT_UPLOAD_PROGRESS",
          meta: { progress: { progress: progress, message: message, failed: error } }
        });
      });

      connection.on("SendScriptUploadCompleted", (filmId) => {
        store.dispatch({
          type: "UPDATE_SCRIPT_UPLOAD_COMPLETED",
          meta: { filmId }
        });
      });

      connection.on("SendScriptUploadErrors", (errors) => {
        store.dispatch({
          type: UPDATE_SCRIPT_UPLOAD_ERROR,
          meta: { errors }
        });

        store.dispatch(showError("Error uploading script."));
        store.dispatch(clearNotifications());
      });

      connection.on("SmartBreakdownProgress", (progress, message) => {
        store.dispatch({
          type: UPDATE_SMART_BREAKDOWN_PROGRESS,
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadSceneBreakDownComplete", (url, fileName) => {
        store.dispatch({
          type: "DOWNLOAD_SCENE_BREAKDOWN_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadProgress", (progress, message) => {
        store.dispatch({
          type: "DOWNLOAD_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadShootingDaysProgress", (progress, message) => {
        store.dispatch({
          type: "DOWNLOAD_SHOOTING_DAYS_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadShootingDaysComplete", (url, fileName) => {
        store.dispatch({
          type: "DOWNLOAD_SHOOTING_DAYS_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadShootingDaysErrored", (errors) => {
        store.dispatch({
          type: "DOWNLOAD_SHOOTING_DAYS_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadShotListProgress", (progress, message) => {
        store.dispatch({
          type: "DOWNLOAD_SHOT_LIST_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadShotListComplete", (url, fileName) => {
        store.dispatch({
          type: "DOWNLOAD_SHOT_LIST_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadShotListErrored", (errors) => {
        store.dispatch({
          type: "DOWNLOAD_SHOT_LIST_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadCallSheetProgress", (progress, message) => {
        store.dispatch({
          type: "DOWNLOAD_CALL_SHEET_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadCallSheetComplete", (url, fileName) => {
        store.dispatch({
          type: "DOWNLOAD_CALL_SHEET_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadCallSheetErrored", (errors) => {
        store.dispatch({
          type: "DOWNLOAD_CALL_SHEET_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadBudgetComplete", (url, fileName) => {
        store.dispatch({
          type: "DOWNLOAD_BUDGET_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadBudgetProgress", (progress, message) => {
        store.dispatch({
          type: "DOWNLOAD_BUDGET_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadBudgetErrored", (errors) => {
        store.dispatch({
          type: "DOWNLOAD_BUDGET_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadPropsOverviewComplete", (url, fileName) => {
        store.dispatch({
          type: DOWNLOAD_PROPS_OVERVIEW + "_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadPropsOverviewProgress", (progress, message) => {
        store.dispatch({
          type: DOWNLOAD_PROPS_OVERVIEW + "_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadPropsOverviewErrored", (errors) => {
        store.dispatch({
          type: DOWNLOAD_PROPS_OVERVIEW + "_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadEquipmentOverviewComplete", (url, fileName) => {
        store.dispatch({
          type: DOWNLOAD_EQUIPMENT_OVERVIEW + "_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadEquipmentOverviewProgress", (progress, message) => {
        store.dispatch({
          type: DOWNLOAD_EQUIPMENT_OVERVIEW + "_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadEquipmentOverviewErrored", (errors) => {
        store.dispatch({
          type: DOWNLOAD_EQUIPMENT_OVERVIEW + "_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadCateringComplete", (url, fileName) => {
        store.dispatch({
          type: DOWNLOAD_SHOOTING_DAY_CATERING + "_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadCateringProgress", (progress, message) => {
        store.dispatch({
          type: DOWNLOAD_SHOOTING_DAY_CATERING + "_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadCateringErrored", (errors) => {
        store.dispatch({
          type: DOWNLOAD_SHOOTING_DAY_CATERING + "_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadDayOutOfDaysComplete", (url, fileName) => {
        store.dispatch({
          type: "DOWNLOAD_DAY_OUT_OF_DAYS_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadDayOutOfDaysProgress", (progress, message) => {
        store.dispatch({
          type: "DOWNLOAD_DAY_OUT_OF_DAYS_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadDayOutOfDaysErrored", (errors) => {
        store.dispatch({
          type: "DOWNLOAD_DAY_OUT_OF_DAYS_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("DownloadScriptComplete", (url, fileName) => {
        store.dispatch({
          type: DOWNLOAD_SCRIPT + "_COMPLETE",
          meta: { url, fileName }
        });
      });

      connection.on("DownloadScriptProgress", (progress, message) => {
        store.dispatch({
          type: DOWNLOAD_SCRIPT + "_PROGRESS",
          meta: { progress: { progress: progress, message: message } }
        });
      });

      connection.on("DownloadScriptErrored", (errors) => {
        store.dispatch({
          type: DOWNLOAD_SCRIPT + "_ERRORED",
          meta: { errors }
        });

        store.dispatch(showError(`Failed to download file`));
        store.dispatch(clearNotifications());
      });

      connection.on("AddUserMessage", (userMessage) => {
        store.dispatch({
          type: "ADD_USER_MESSAGE",
          meta: { userMessage }
        });

        const message = userMessage.entityName + ": " + userMessage.message;
        if (userMessage.severity === 1) {
          store.dispatch(showInfo(message));
        }

        if (userMessage.severity === 2) {
          store.dispatch(showWarning(message));
        }

        if (userMessage.severity === 3) {
          store.dispatch(showError(message));
        }


        store.dispatch(clearNotifications());
      });

      connection.on("RemoveUserMessage", (userMessage) => {
        store.dispatch({
          type: "REMOVE_USER_MESSAGE",
          meta: { userMessage }
        });
      });

      connection.on("DismissUserMessage", (userMessageId) => {
        store.dispatch({
          type: "DISMISS_USER_MESSAGE",
          meta: { userMessageId }
        });
      });

      // re-establish the connection if connection dropped
      connection.onclose(() =>
        setTimeout(() => startSignalRConnection(connection), 5000)
      );

      startSignalRConnection(connection);
    }

    return next(action);
  };
}
