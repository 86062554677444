import getApiClient from "../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../notifications/ducks/actions";

const url = "/film";

export const FETCH_FILM_DEFAULTS = "FETCH_FILM_DEFAULTS";
export const COPY_FILM = "COPY_FILM";
export const EXPORT_FILM = "EXPORT_FILM";
export const FILM_GET_COUNTRIES = "FILM_GET_COUNTRIES";
export const ADD_FILM_GET_COUNTRIES = "ADD_FILM_GET_COUNTRIES";
export const CLEAR_USER_FILM = "CLEAR_USER_FILM";

export function fetchFilms() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_FILMS",
      payload: getApiClient(true).get(url),
    });
  };
}

export function fetchFilm(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_FILM",
      payload: getApiClient().get(`${url}/${id}`),
    });
  };
}

export function fetchFilmDefaults() {
  return (dispatch) => {
    dispatch({
      type: FETCH_FILM_DEFAULTS,
      payload: getApiClient().get(`${url}/defaults`),
    });
  };
}

export function clearUserFilm() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_FILM
    });
  };
}

export function fetchUserFilm(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_FILM",
      payload: getApiClient(true).get(`${url}/${id}/UserFilm`),
    });
  };
}

export function updateFilm(film, image) {
  const formPayload = new FormData();
  formPayload.append('model', JSON.stringify(film))
  formPayload.append('file', image);
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_FILM",
      meta: { film, image },
      payload: getApiClient().put(`${url}/${film.id}`, formPayload),
    })
      .then((response) => {
        dispatch(showInfo(`Updated film ${film.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating film ${film.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function getCountries() {
  return (dispatch) => {
    dispatch({
      type: FILM_GET_COUNTRIES,
      payload: getApiClient().get(`/lookup/countries`),
    });
  };
}

export function getCountriesForAdd() {
  return (dispatch) => {
    dispatch({
      type: ADD_FILM_GET_COUNTRIES,
      payload: getApiClient().get(`/lookup/countries`),
    });
  };
}


function showFile(blob, filmName) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  debugger;
  var newBlob = new Blob([blob], { type: "txt/json" });

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;

  link.download = filmName + ".json";
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export function exportFilm(film) {
  return (dispatch) => {
    dispatch({
      type: EXPORT_FILM,
      meta: { film },
      payload: getApiClient().post(`${url}/${film.id}/export`, {}),
    })
      .then((r) => {
        debugger;
        return r.value.data.blob();
      })
      .then((blob) => {
        debugger;
        showFile(blob, film.name);
      })
      .catch((response) => {
        dispatch(showError(`Error exporting film ${film.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addFilm(film, image) {
  const formPayload = new FormData();
  formPayload.append('model', JSON.stringify(film))
  formPayload.append('file', image);
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_FILM",
      payload: getApiClient().post(url, formPayload),
      meta: { film }
    })
      .then((response) => {
        dispatch(showInfo(`Added film ${film.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding film ${film.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function copyFilm(filmId) {
  return (dispatch) => {
    dispatch({
      type: COPY_FILM,
      payload: getApiClient().post(`${url}/${filmId}/Copy`),
    })
      .then((response) => {
        dispatch(showInfo(`Film copied`));
      })
      .catch((response) => {
        debugger;
        dispatch(showError(`Error copying film`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addExampleFilm() {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_EXAMPLE_FILM",
      payload: getApiClient().post(`${url}/Example`),
    })
      .then((response) => {
        dispatch(showInfo(`Example film created`));
      })
      .catch((response) => {
        dispatch(showError(`Error creating example film`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteFilm(film) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_FILM",
      payload: getApiClient().delete(`/film/${film.id}`),
      meta: { film },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted film ${film.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting film ${film.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function archiveFilm(film) {
  return (dispatch) => {
    dispatch({
      type: "ARCHIVE_SINGLE_FILM",
      payload: getApiClient().put(`/film/${film.id}/Archive`),
      meta: { film },
    })
      .then((response) => {
        dispatch(showInfo(`Archived film ${film.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error archiving film ${film.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}

export function setOnboardingStep(step) {
  return (dispatch) => {
    dispatch({
      type: "SET_ACTIVE_FILM_ONBOARDING_STEP",
      payload: step
    });
  };
}
