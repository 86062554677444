import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  sendPotentialDates,
  fetchAvailability,
  sendPotentialDatesToPerson,
  sendPotentialDatesToSelf
} from "./ducks/actions";
import SendPotentialDates from "./send-potential-dates";
import Loader from "../../../shared/loader";
import confirm from "../../../shared/modal/confirm";
import EmailRecipients from "../../shooting-days/call-sheet/email-recipients";
import { Actor, BackgroundActor, CrewMember } from "../../shooting-days/messages/ducks/reducer";

const SchedulingContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    peopleDetailsProgress,
    sendPotentialDates,
    fetchAvailability,
    sendPotentialDatesToPerson,
    sendPotentialDatesToSelf,
    loading,
    saving,
    readOnly,
    progress } = props;

  useEffect(() => {
    fetchAvailability(filmId);
  }, []);

  const onSendSchedule = () => {
    if (peopleDetailsProgress.hasPeopleWithoutEmailAddress) {
      confirm(() => sendPotentialDates(filmId), null, "Some people have no email addresses, see list below. Do you want to send to the ones that have?", "Missing Email Addresses");
    } else {
      confirm(() => sendPotentialDates(filmId), null, "Are you sure you want to send potential dates email to all actors and crew?", "Confirmation");
    }
  }

  const onSendPerson = (personId, personType, fullName, resend) => {
    confirm(() => {
      sendPotentialDatesToPerson(filmId, personId, personType);
    }, null, `Are you sure you want to ${resend ? "re" : ""}send potential dates email to ${fullName}?`, "Confirmation");
  };

  const onSendPotentialDatesToSelf = () => {
    sendPotentialDatesToSelf(filmId);
  }

  return (
    <ScrollIntoView
      className="blade-content wider"
      path="/films/:filmId/scheduling"
      loading={loading}>
      <h2 className="menu-label">Send Potential Dates</h2>
      {(saving || loading) && <Loader />}
      <SendPotentialDates
        readOnly={readOnly}
        sendSchedule={onSendSchedule}
        onSendToSelf={onSendPotentialDatesToSelf}
        progress={progress} />

      {!loading && <EmailRecipients filmId={filmId}
        shootingDaySettings={peopleDetailsProgress}
        onSendPerson={onSendPerson}
        includeClicked={true}
        canSend={true}
        actorType={Actor}
        backgroundActorType={BackgroundActor}
        crewMemberType={CrewMember}
        clickedName="Viewed"
        totalStatuses={7}
        includeSubmitted={true}
        canAccessActors={peopleDetailsProgress.canAccessActors}
        canAccessBackgroundActors={peopleDetailsProgress.canAccessBackgroundActors}
        canAccessCrew={peopleDetailsProgress.canAccessCrew}
        submittedName="Saved" />}
    </ScrollIntoView>
  );
};

function mapStateToProps(state) {
  const { schedulingState } = state;
  const sendPotentialDatesState = schedulingState.sendPotentialDatesState;
  return {
    peopleDetailsProgress: sendPotentialDatesState.peopleDetailsProgress,
    errors: sendPotentialDatesState.errors,
    loading: sendPotentialDatesState.loading,
    saving: sendPotentialDatesState.saving,
    readOnly: sendPotentialDatesState.readOnly,
    progress: sendPotentialDatesState.progress
  };
}

export default connect(mapStateToProps,
  {
    sendPotentialDates,
    fetchAvailability,
    sendPotentialDatesToPerson,
    sendPotentialDatesToSelf
  })(SchedulingContainer);
