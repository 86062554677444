import React, { useEffect } from "react";
import Dropzone from "react-dropzone";
import NumberFormat from "react-number-format";
import Combobox from "react-widgets/lib/Combobox";
import Creatable from "react-select/creatable";
import { connect } from "react-redux";
import Errors from "./../shared/errors";
import { getUnions } from "../app/ducks/actions";
import {
  initScriptUpload,
  setUploadError,
  uploadScript,
  orderCharactersByScenes,
  orderCharactersByName,
  clearRedirect,
  setActorUnion,
  setCrewUnion,
  setCurrencyId,
  setLanguageId,
  setBudget,
  setUseAutopBreakdown,
  getProgress
} from "./ducks/actions";
import "./index.scss";
import UploadResult from "./upload-result";
import HelpText from "../shared/help-text";
import { useState } from "react";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import Progress from "../shared/progress";
import FilmanizeSelect, { styles } from "../shared/filmanize-select";
import Onboarding from '../shared/onboarding/onboarding';
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName } from './ducks/reducer';
import BoxWraper from "../account/box-wrapper";
import Loader from "../shared/loader";
import FilmanizeSelectField from "../shared/filmanize-select-field";
import UploadScript from "./upload-script";
import FilmanizeCheckbox from "../shared/filmanize-checkbox";

import UploadForm from "./upload-form";

interface IProps {
  uploadStatus: string;
}

export const acceptedFileTypes = [".fdx", ".cxscript", ".celtx", ".fountain", ".highland"];

const ScriptUpload = (props: any) => {
  const {
    loading,
    uploadScript,
    uploadStatus,
    setUploadError,
    initScriptUpload,
    errors,
    progress,
    clearRedirect,
    onboardingSteps,
    currencies,
    userCurrencyId,
    loadingOnBoarding,
    getUnions,
    actorUnions,
    crewUnions,
    actorUnionSelectedValue,
    setActorUnion,
    crewUnionSelectedValue,
    setCrewUnion,
    currencyId,
    setCurrencyId,
    languages,
    languageId,
    userLanguageId,
    setLanguageId,
    setBudget,
    budget,
    setUseAutopBreakdown,
    useAutoBreakdown,
    getProgress,
    isInProgress
  } = props;

  useEffect(() => {
    initScriptUpload();
    getUnions();
    getProgress();
  }, []);

  if (props.redirect) {
    if (props.redirect.to) {
      clearRedirect();
      const url = props.redirect.to;
      return <Redirect to={url} />;
    }
  }

  var openAISchemaForBackEnd = {
    "type": "object",
    "properties": {
      "ScriptLanguageIso639Code": { "type": "string" },
      "Costumes": { "type": "array", "items": { "type": "object", "properties": { "Character": { "type": "string" }, "Costumes": { "type": "array", "items": { "type": "string" } } } } },
      "SetDressings": { "type": "array", "items": { "type": "object", "properties": { "Name": { "type": "string" }, "Quantity": { "type": "integer" } } } },
      "Props": { "type": "array", "items": { "type": "object", "properties": { "Name": { "type": "string" }, "Quantity": { "type": "integer" } } } },
      "BackgroundCharacters": { "type": "array", "items": { "type": "string" } },
      "Makeup": { "type": "array", "items": { "type": "object", "properties": { "Character": { "type": "string" }, "Makeup": { "type": "array", "items": { "type": "string" } } } } },
      "Characters": {
        "type": "array", "items": {
          "type": "object",
          "properties": {
            "Name": { "type": "string" },
            "ShortName": { "type": "string" },
            "Description": { "type": "string" },
            "Age": { "type": "string" },
            "Quantity": { "type": "integer" },
            "Gender": { "type": "string" }
          }
        }
      },
      "SceneSummary": { "type": "string" }
    },
    "required": ["ScriptLanguageIso639Code", "Costumes", "SetDressings", "Props", "BackgroundCharacters", "Makeup", "Characters", "SceneSummary"]
  };


  return (
    <>
      {loading && <Loader />}
      <UploadForm loading={loading}
        uploadScript={uploadScript}
        uploadStatus={uploadStatus}
        setUploadError={setUploadError}
        errors={errors}
        progress={progress}
        clearRedirect={clearRedirect}
        onboardingSteps={onboardingSteps}
        currencies={currencies}
        userCurrencyId={userCurrencyId}
        loadingOnBoarding={loadingOnBoarding}
        actorUnions={actorUnions}
        crewUnions={crewUnions}
        actorUnionSelectedValue={actorUnionSelectedValue}
        setActorUnion={setActorUnion}
        crewUnionSelectedValue={crewUnionSelectedValue}
        setCrewUnion={setCrewUnion}
        currencyId={currencyId}
        setCurrencyId={setCurrencyId}
        languages={languages}
        languageId={languageId}
        userLanguageId={userLanguageId}
        setLanguageId={setLanguageId}
        setBudget={setBudget}
        budget={budget}
        setUseAutopBreakdown={setUseAutopBreakdown}
        useAutoBreakdown={useAutoBreakdown}
        isInProgress={isInProgress} />
    </>
  );
};

const mapStateToProps = function (state) {
  const { scriptsState, commonState } = state;
  return {
    loading: scriptsState.loading,
    uploadStatus: scriptsState.uploadingStatus,
    redirect: scriptsState.redirect,
    script: scriptsState.script,
    errors: scriptsState.errors,
    progress: scriptsState.progress,
    characterOrderBy: scriptsState.characterOrderBy,
    onboardingSteps: scriptsState.onboardingSteps,
    actorUnionSelectedValue: scriptsState.actorUnionSelectedValue,
    crewUnionSelectedValue: scriptsState.crewUnionSelectedValue,
    budget: scriptsState.budget,
    currencies: commonState.currencies,
    currencyId: scriptsState.currencyId,
    userCurrencyId: commonState.userCurrencyId,
    languages: commonState.languages,
    languageId: scriptsState.languageId,
    userLanguageId: commonState.userLanguageId,
    loadingOnBoarding: commonState.loadingOnBoarding,
    actorUnions: commonState.actorUnions,
    crewUnions: commonState.crewUnions,
    useAutoBreakdown: scriptsState.useAutoBreakdown,
    isInProgress: scriptsState.isInProgress
  };
};

export default connect(mapStateToProps, {
  uploadScript,
  initScriptUpload,
  setUploadError,
  orderCharactersByScenes,
  orderCharactersByName,
  clearRedirect,
  getUnions,
  setActorUnion,
  setCrewUnion,
  setCurrencyId,
  setLanguageId,
  setBudget,
  setUseAutopBreakdown,
  getProgress
})(ScriptUpload);
