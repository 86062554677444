import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import FormErrors from "../../shared/form-errors";
import formatBytes from "../../shared/formatBytes";
import Errors from "../../shared/errors";
import { Link } from "react-router-dom";
import { renderCheckbox, renderReactSelect, renderSelectField, renderSingleReactSelect, renderTextField } from "../../shared/form-helpers";
import FilmaizeSelect from "../../shared/filmanize-select";


function UsersForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    user,
    disableUser,
    pricingPlans,
    cancelUpload
  } = props;

  const mappedPricingPlans = pricingPlans?.map((pricingPlan: any) => { return { label: pricingPlan.name, value: pricingPlan.pricingPlanType } });

  return (
    <>
      <h2 className="menu-label">User</h2>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        {user &&
          <>
            <table className="table">
              <thead>
                <tr>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{user.firstName}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{user.lastName}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td><a href={`mailto:${user.emailAddress}`}>{user.emailAddress}</a></td>
                </tr>
                <tr>
                  <td>Email Confirmed</td>
                  <td>{user.emailConfirmed ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{user.country}</td>
                </tr>
                <tr>
                  <td>Language</td>
                  <td>{user.language} {user.languageCode}</td>
                </tr>
                <tr>
                  <td>First Login</td>
                  <td>{user.firstLogin ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Registered Date</td>
                  <td>{user.registeredDate}</td>
                </tr>
                <tr>
                  <td>First Login Date</td>
                  <td>{user.firstLoginDate}</td>
                </tr>
                <tr>
                  <td>Last Login Date</td>
                  <td>{user.lastLoginDate}</td>
                </tr>
                <tr>
                  <td>Referrer</td>
                  <td>{user.referrer}</td>
                </tr>
                <tr>
                  <td>Referred From UserId</td>
                  <td><Link to={`/admin/users/${user.referredFromUserId}`}>{user.referredFromUserId}</Link></td>
                </tr>
                <tr>
                  <td>ReferralCode</td>
                  <td><Link to={`/referral/${user.referralCode}`} target="_blank">{user.referralCode}</Link></td>
                </tr>
                <tr>
                  <td>Bytes Used</td>
                  <td>{user.bytesUsed}</td>
                </tr>
                <tr>
                  <td>Email Newsletter</td>
                  <td>{user.emailNewsletter ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Referred Users Count</td>
                  <td>{user.referredUsersCount}</td>
                </tr>
                <tr>
                  <td>Billing Currency Code</td>
                  <td>{user.billingCurrencyCode}</td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td>{user.paymentStatus}</td>
                </tr>
                <tr>
                  <td>Last PaymentId</td>
                  <td>{user.lastPaymentId}</td>
                </tr>
                <tr>
                  <td>SubscriptionId</td>
                  <td>{user.subscriptionId}</td>
                </tr>
                <tr>
                  <td>Subscription ItemId</td>
                  <td>{user.subscriptionItemId}</td>
                </tr>
                <tr>
                  <td>Has Subscription</td>
                  <td>{user.hasSubscription ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Last Paid Date</td>
                  <td>{user.lastPaidDate}</td>
                </tr>
                <tr>
                  <td>Next Billing Date</td>
                  <td>{user.nextBillingDate}</td>
                </tr>
                <tr>
                  <td>Stripe CustomerId</td>
                  <td>{user.stripeCustomerId}</td>
                </tr>
                <tr>
                  <td>Pause On Next Billing Date</td>
                  <td>{user.pauseOnNextBillingDate ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Cancel On Next Billing Date</td>
                  <td>{user.causeOnNextBillingDate ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>DiscountIds</td>
                  <td>{user.discountIds?.join(", ")}</td>
                </tr>
                <tr>
                  <td>Enabled</td>
                  <td>{user.enabled ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Upload Progress</td>
                  <td>{user.uploadProgress}</td>
                </tr>
              </tbody>
            </table>

            <Field
              name="isTeacher"
              label="Teacher"
              component={renderCheckbox} />
            <Field
              name="isStudent"
              label="Student"
              component={renderCheckbox} />
            <Field
              name="pricingPlanType"
              label="Pricing Plan Type"
              component={renderSingleReactSelect}
              isMulti={false}
              options={mappedPricingPlans} />
            <Field
              name="pricePerMonth"
              label="Price Per Month"
              component={renderTextField} />
            <Field
              name="maximumBytes"
              label="Maximum Bytes"
              component={renderTextField} />
            <Field
              name="maximumFilms"
              label="Maximum Films"
              component={renderTextField} />
            <Field
              name="usedFilms"
              label="Used Films"
              component={renderTextField} />
            <Field
              name="maximumCollaboratorsPerFilms"
              label="Maximum Collaborators Per Films"
              component={renderTextField} />
            <Field
              name="canAddProductionCompanyLogo"
              label="Can Add Production Company Logo"
              component={renderCheckbox} />
          </>

        }
        <div className="buttons mt-1 sticky-buttons">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Update
          </button>
          {!user?.enabled && <button
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={submitting}>
            Approve
          </button>}
          {user?.enabled && <button
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            onClick={disableUser}
            disabled={submitting}>
            Disable
          </button>}
          {(user?.uploadProgress > 0) && <button
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            onClick={cancelUpload}
            disabled={submitting}>
            Cancel Upload
          </button>}
          <Link className="ml-1" to={`/admin/users/${user?.id}/films`}>Films</Link>
          <Link className="ml-1" to="/admin/users">Users</Link>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(UsersForm);
