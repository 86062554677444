import React from "react";
import DepartmentMenu from "./menu";
import ListDepartmentsContainer from "./list-container";
import AddDepartment from "./add";
import EditDepartment from "./edit";
import RoleRoutes from "./roles/routes";
import OverviewRoutes from "../overview/routes";
import UploadContainer from "../upload/container";
import CopyContainer from "../copy/container";
import { PrivateRoute } from "../../../shared/private-route";

const DepartmentRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/departments"
        component={ListDepartmentsContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/add"
        component={AddDepartment}
      />
      <PrivateRoute
        path="/films/:filmId/departments/:departmentId"
        component={DepartmentMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/edit"
        component={EditDepartment}
      />

      <PrivateRoute
        path="/films/:filmId/departments/upload"
        component={UploadContainer}
      />

      <PrivateRoute
        path="/films/:filmId/departments/copy"
        component={CopyContainer}
      />

      <RoleRoutes />
      <OverviewRoutes />
    </>
  );
};

export default DepartmentRoutes;
