import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import CopyCrew from "./copy";
import { fetchFilms } from "../../film/ducks/actions";
import { copyCrew, clearRedirect } from "./ducks/actions";
import { useEffect, useState } from "react";
import Loader from "../../../shared/loader";
import { Redirect } from "react-router-dom";
import Onboarding from "../../../shared/onboarding/onboarding";

const CopyCrewContainer = (props) => {
    const { fetchFilms, copyCrew, films, loadingFilms, redirect, clearRedirect, importing, onboardingSteps } = props;
    const { filmId } = props.match.params;

    const [filmsExcludingThisOne, setFilmsExcludingThisOne] = useState<any[]>([]);
    const [selectedFilm, setSelectedFilm] = useState<any>(null);

    const onChangeFilm = (film) => {
        setSelectedFilm(film);
    };

    useEffect(() => {
        fetchFilms();
    }, []);

    useEffect(() => {
        if (films?.length) {
            setFilmsExcludingThisOne(films.filter(film => film.id != filmId));
        }
    }, [filmId, films?.length]);

    useEffect(() => {
        if (filmsExcludingThisOne?.length) {
            setSelectedFilm(filmsExcludingThisOne[0].id)
        }
    }, [filmId, filmsExcludingThisOne?.length]);

    const onCopyCrew = () => {
        copyCrew(filmId, selectedFilm);
    };

    if (redirect) {
        if (redirect.to == "list") {
            clearRedirect();
            const url = `/films/${filmId}/departments/overview`;
            return <Redirect to={url} />;
        }
    }

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/departments/copy">
            <h2 className="menu-label">Copy Crew</h2>
            {(loadingFilms || importing) && <Loader />}
            <CopyCrew films={filmsExcludingThisOne} copyCrew={onCopyCrew} onChangeFilm={onChangeFilm} selectedFilm={selectedFilm} />
            <Onboarding onboardingSteps={onboardingSteps} section="Copy Crew" />
        </ScrollIntoView>
    );
};

function mapStateToProps(state) {
    const { errors, importing, redirect, onboardingSteps } = state.crewMembersCopyState
    return {
        films: state.filmsState.films,
        importing: importing,
        redirect: redirect,
        onboardingSteps: onboardingSteps,
        errors: errors
    };
}

export default connect(mapStateToProps, {
    fetchFilms,
    copyCrew,
    clearRedirect
})(CopyCrewContainer);

